@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/*Colors ans Styling*/
.App{
    position: relative;
    overflow-x: clip ;
}
.App>:nth-child(1){
    position: relative;
     background-color: var(--black);
}
.white-gradient{
    position: absolute;
    top: 50px;
    width: 20rem;
    height: 20rem;
    background-color: rgba(255,255,255,0.522);
    filter: blur(100px);
    border-radius: 100px;
}