.hero-Wrapper{
    color: white;
   
    padding-bottom: 2rem;
    bottom: 90px;
   z-index: 1;
}
.hero-container{
    justify-content: space-around;
    align-items: flex-end;
    margin-top: -80px;
}
/* left side */
.hero-left{
    gap: 3rem;
}
.hero-title{
    position: relative;
    margin-top: 150px;
    z-index: 1;
}
.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}
.orange-circle{
    height: 4rem;
    width: 4rem;
    --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
} 
.search-bar{
    background-color: white;
    border-radius: 5px;
    border : 3px solid rgba(120,120,120,0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}
.search-bar>input{
    border: none;
    outline: none;
}
.stats{
    width: 100%;
    justify-content: space-between;
    
}
.main-btn{
    font-weight: 500;
    padding:0.6rem 1.4rem;
    color: white;
    background: var(--blue-gradient);
    border: none;
    border-radius: 4px;
    transition: all 300ms ease-in;
  
  }
.main-btn:hover
  {
    cursor: pointer;
    transform: scale(1.1);
  }
.stat>:nth-child(1){
    font-size: 2rem;
}
.stat>:nth-child(1)>:last-child{
    color: orange ;
}
/* right side */
.img-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255,255,255,0.12);
}
.img-container>img{
    width: 100%;
    height: 100%;
}
@media (max-width: 640px) {
    .hero-container {
      margin-top: 2rem;
    }
    .hero-title > h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    .img-container {
      width: 30rem; /* Set a fixed width to maintain the container's size */
      height: 25rem;
    }
    .stats {
      justify-content: center;
      gap: 1.5rem;
    }
  
    .hero-right {
      width: 100%;
    }
    .stat > :nth-child(1) {
      font-size: 1.5rem;
    }
    .stat > :nth-child(2) {
      font-size: 0.8rem;
    }
  }
  