.c-container{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
/* .c-container div{
    flex: 1;
} */
.c-right{
    width: 100%;
}
.c-left{
    gap: 0.5rem;
}
.contactModes{
    margin-top: 2rem;
    gap: 1rem;
}
.contactModes .row{
    gap: 1.5rem;
}
.call{
    display: flex;
    justify-content: flex-start;
}
.mode{
    width: 16rem;
    padding: 1rem;
    border: 0.8px solid rgba(128,128,128,0.143);
    gap: 1rem;
    border-radius: 5px;
    transition: all 300ms ease-in;
}
.mode .main-btn{
    width: 100%;
    background: var(--lightBlue);
    color: var(--blue);
    font-size: 0.9rem;
    font-weight: 600;
}
.mode>:nth-child(1){
    width: 100%;
    gap:1.6rem;
}
.mode .detail .primaryText{
    font-size: 1.1rem;
    font-weight: 600;
}
.mode:hover {
    transform: scale(1.1); /* Corrected property name */
    box-shadow: var(--shadow);
  }
  
  .mode .main-btn:hover {
    background: var(--blue-gradient);
    color: white;
    transform: scale(0.8); /* Corrected property name */
  }

@media (max-width:1024px){
    .c-container{
        flex-direction: column;
    }
   
    .contactModes{
        width: 100%;
    }
    .row{
        flex-direction: column;
        width: 100%;
    }
    .mode{
       width: 100%; 
    }
    .img-container{
        justify-content: center;
    }

}