.h-wrapper{
    /* background-color: var(--black); */
    color: white;
}
.h-container{
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
    display: flex;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.header{
    gap: 2rem;

}

.header a:hover{
    cursor: pointer;
}
.contact{
    font-weight: 500;
    padding:0.6rem 1.4rem;
    color: white;
    background: var(--blue-gradient);
    border: none;
    border-radius: 4px;
    transition: all 300ms ease-in;
  
  }
.contact:hover{
    cursor: pointer;
    transform: scale(1.1);
  }

  
.menu-icon{
    display: none;
}

@media (max-width: 768px){
    .menu-icon{
        display: block ;
    }
    .header{
        z-index: 99;
        color: var(--black);
        position: absolute;
        top: 5rem;
        right: 3rem;
        background-color: white;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.05);
        transition: all 300ms ease-in;
    }
}